import React from 'react';

import './variables.scss';
import './App.scss';
import { Layout, Header, Navigation, Drawer, Content } from 'react-mdl';
import Main from './components/main';
import { HashLink as Link } from 'react-router-hash-link';

function App() {
  return (
    <div className="content">
    <Layout>
        <Header className= "header-color" title=" " scroll>
            <Navigation className="navigation">
                <Link smooth to="/#about_me">About me</Link>
                <Link smooth to="/#sites_section">Sites i've worked on</Link>
                <Link smooth to="/#contact_section">Contact me</Link>
            </Navigation>
        </Header>
        <Drawer>
            <Navigation>
                <Link smooth to="/#header">Back to top</Link>
                <Link smooth to="/#about_me">About me</Link>
                <Link smooth to="/#sites_section">Sites i've worked on</Link>
                <Link smooth to="/#contact_section">Contact me</Link>
            </Navigation>
        </Drawer>
        <Content>
            <Main/>
        </Content>
    </Layout>
</div>
  );
}

export default App;
