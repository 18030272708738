import React from "react";
import ReactCardFlip from "react-card-flip";
import { CardTitle } from "react-mdl";
import Data from "./data.json";

const Card = ({ project }) => {
  const [isFlipped, setIsFlipped] = React.useState(false);
  const handleClick = () => {
    setIsFlipped(!isFlipped);
  };
  
  return (
    <ReactCardFlip className="site-card" isFlipped={isFlipped} flipDirection="horizontal">
      <CardTitle
        style={{background: `url(${project.image}) center / cover , #FFF`,}}
        title={project.title}
        onClick={handleClick}
        className="CardFront cardStyleFront"
      >
      </CardTitle>
      <CardTitle
        onClick={handleClick}
        className="CardBack cardStyleBack"
      >
        <div className="card-detail">
        <h2 className="site-name">{project.title}</h2>
        <p>{project.description}</p>
        <a className="button" href={project.link} rel="noopener noreferrer" target='_blank'><span>View the site</span></a>
        </div>
      </CardTitle>
    </ReactCardFlip>
  );
};

const Projects = () => {
  return (
    <div className= "header">
      <h2>Sites i've worked on</h2>
      <div className= "sites-worked-on">
        
        {Data.map((item, index) => (
          <Card project={item} key={`card-${index}`} shadow={0} style={{margin: 'auto'}}/>
        ))}
      </div>
    </div>
  );
};

export default Projects;