import React, { Component } from 'react';
import { Grid, Cell, List, ListItem, ListItemContent, Icon } from 'react-mdl';
import Projects from './projects'

class LandingPage extends Component {
  render() {
    return (
      
      <div className="container" style={{width: '100%', margin: 'auto'}}>
        <a id="header" href="/"> </a>
        <Grid className="landing-grid">
          <Cell col={12}>
            <img
            src= "me.JPG"
            loading="eager"
            alt= "myself"
            className= "avatar-img"/>
            <div className= "blurred-box">
              <h1>DAN DAVISON</h1>
              <h2>web developer</h2>

              <hr/>
              <p>HTML/CSS | SASS | PHP | MySQL | Drupal 7/8 | JavaScript | React</p>

              <div className= "social-links">

                {/* LinkedIn */}
                <a href="https://www.linkedin.com/in/davisonwebdev/" title="My LinkedIn" rel="noopener noreferrer" target="_blank">
                  <i className="fa fa-linkedin-square" aria-hidden="true"/>
                </a>

                {/* GitHub */}
                  <a href="https://github.com/davisonwebdev" title="My GitHub" rel="noopener noreferrer" target="_blank">
                  <i className="fa fa-github-square" aria-hidden="true"/>
                </a>
                
                {/* Drupal */}
                <a href="https://www.drupal.org/u/doubledee" title="My Drupal Account" rel="noopener noreferrer" target="_blank">
                  <i className="fa fa-drupal" aria-hidden="true"/>
                </a>

                {/* Twitter */}
                <a href="http://twitter.com/davisonwebdev?s=07" title="My Twitter Profile" rel="noopener noreferrer" target="_blank">
                  <i className="fa fa-twitter-square" aria-hidden="true"/>
                </a>             

              </div>
            </div>
          </Cell>
        </Grid>
        <a id="about_me" href="/"> </a>
        <div className="about-me">
          <h2>About me</h2>
          <p>Hello! I have been doing web development for five years and i'm always up for learning something new.
             I took an interest in development at a relatively young age and eventually went on to apply for
              a Web Development Apprenticeship at CTI Digital, the apprenticeship was run by Happy Computers.
             I completed my Drupal Apprenticeship at CTI Digital and went onto become a developer there where
              I worked on a variety of Drupal projects. You can view some of these projects below.</p> 
              <p>I have recently started to look into other web frameworks such as React which this site has
               been built in. I would like to further extend my development knowledge outside of Drupal.</p>
        </div>
        <a id="sites_section" href="/" className="sites-section"> </a>
        <Projects />
        <a id="contact_section" href="/" className="contact-section"> </a>
        <Grid className= "contact-grid">
          <Cell col={4}>
            <h2>Social media</h2>
              <div className="social-links-footer">
                {/* LinkedIn */}
                <a href="https://www.linkedin.com/in/davisonwebdev/" title="My LinkedIn" rel="noopener noreferrer" target="_blank">
                  <i className="fa fa-linkedin-square" aria-hidden="true"/>
                </a>
                {/* Twitter */}
                <a href="http://twitter.com/davisonwebdev?s=07" title="My Twitter Profile" rel="noopener noreferrer" target="_blank">
                  <i className="fa fa-twitter-square" aria-hidden="true"/>
                </a>
              </div>
          </Cell>
          <Cell col={4}>
            <div className= "contact-details">
              <h2>Get in touch</h2>
              <List>
                <ListItem>
                <a className='icon' href="mailto:davisonwebdev@gmail.com" title="My Email - davisonwebdev@gmail.com" rel="noopener noreferrer" target="_blank" style={{color: 'white', textDecoration: 'none'}}><Icon name="mail" /></a>
                  <ListItemContent>
                    <a className='text' href="mailto:davisonwebdev@gmail.com" title="My Email - davisonwebdev@gmail.com" rel="noopener noreferrer" target="_blank" style={{color: 'white', textDecoration: 'none'}}>davisonwebdev@gmail.com</a>
                    </ListItemContent>
                </ListItem>
                <ListItem>
                  <a className='icon' href="tel:+447824427781" title="My mobile number - 07824427781" rel="noopener noreferrer" target="_blank" style={{color: 'white', textDecoration: 'none'}}><Icon name="phone" /></a>
                  <ListItemContent>
                    <a className='text' href="tel:+447824427781" title="My mobile number - 07824427781" rel="noopener noreferrer" target="_blank" style={{color: 'white', textDecoration: 'none'}}>07824427781</a>
                  </ListItemContent>
                </ListItem>
              </List>
            </div>
          </Cell>
        </Grid>
        <div className="footer">
          Daniel Davison 2020
        </div>
      </div>
    )
  }
}
// <SpotifyPlayer />
export default LandingPage;